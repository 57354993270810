import React, { useContext, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Frame } from 'framer';
import * as app from '.';
import { ApplicationContext } from '../context';

export default () => {
	const location = useLocation();
	const { state, dispatch } = useContext(ApplicationContext);
	const { theme } = state;
	const [locale, setLocale] = useState(`PT`);
	const [localeLabel, setLocaleLabel] = useState(`PT`);
	const firstRef = useRef(true);

	useEffect(() => {
		const newLocale = state.locale.toUpperCase() === `PT` ? `EN` : `PT`;
		setLocaleLabel(newLocale);
	}, [state]);

	const toggle = () => {
		const newLocale = locale === `PT` ? `EN` : `PT`;

		let translated;

		if (state.url.indexOf(`/pt`) === -1) {
			translated = state.url.replace(`/en`, `/pt`);
		} else {
			translated = state.url.replace(`/pt`, `/en`);
		}

		dispatch({ type: `REQUEST_NAVIGATION`, url: translated });
	};

	return (
		<React.Fragment>
			<app.Desktop>
				<Frame
					right={`3vw`}
					top={`3.4vh`}
					size={`2.7vw`}
					radius={`50%`}
					background={``}
					border={theme === `dark` ? `2px solid white` : `2px solid black`}
					style={{
						color: theme === `dark` ? `white` : `black`,
						textTransform: `uppercase`,
						fontFamily: `HelveticaNeueMedium`,
						fontSize: `0.7vw`,
					}}
					whileTap={{ scale: 0.9 }}
					onTap={toggle}>
					{localeLabel}
				</Frame>
			</app.Desktop>
		</React.Fragment>
	);
};
