import React from 'react';
import { AnimatePresence } from 'framer';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import * as route from './routes';
import * as app from './components';

export default () => {
	const location = useLocation();
	return (
		<React.Fragment>
			<app.Background />
			<app.Marca />
			<Switch location={location} key={location.pathname}>
				<Route exact path='/:lang?'>
					<route.Main />
				</Route>
				<Route exact path='/:lang/biografia'>
					<route.ContentBiografia />
				</Route>
				<Route exact path='/:lang/livro-obra'>
					<route.Content />
				</Route>
				<Route exact path='/:lang/servicos'>
					<route.Content />
				</Route>
				<Route>
					<Redirect to={{ pathname: '/pt' }} />
				</Route>
			</Switch>
			<app.Navigation />
			<app.Locale />
			<app.Indicator />
		</React.Fragment>
	);
};
