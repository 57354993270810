import React, { useContext, useState, useEffect } from 'react';
import { Frame, Scroll, Stack, motion } from 'framer';
import * as app from '../components';
import { ApplicationContext } from '../context';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import dompurify from 'dompurify';

export default () => {
	const [local, setLocal] = useState({
		title: ``,
		news: { label: ``, entries: [] },
		quotes: [],
		seal: ``,
		meta: { title: ``, description: `` },
	});
	const { state } = useContext(ApplicationContext);

	useEffect(() => {
		if (!isEmpty(state)) {
			let _local = {};
			if (!isEmpty(state.data)) {
				_local = { ...state.data };
			}
			if (isEmpty(_local.meta)) {
				_local.meta = { title: ``, description: `` };
			}
			setLocal(_local);
		}
	}, [state]);

	return (
		<app.Page>
			<app.SEO title={local.meta.title} description={local.meta.description} url={local.url} />
			<app.Desktop>
				<Frame background={`black`} x={`50vw`} width={`50vw`} height={`100vh`} />
				<Panel>
					<Title>{local.title}</Title>
					<Spacer />
					{/* {local.news && (
						<React.Fragment>
							<News>{local.news.label}</News>
							<app.News news={local.news.entries} />
						</React.Fragment>
					)} */}
					<Content
						dangerouslySetInnerHTML={{
							__html: dompurify.sanitize(local.content),
						}}
					/>
				</Panel>

				{local.quotes && <app.Phrases quotes={local.quotes} />}
				{!isEmpty(local.seal) && <app.Centenario label={local.seal} />}
			</app.Desktop>
			<app.Mobile>
				<TitleMobile>{local.title}</TitleMobile>
				{local.quotes && <app.Phrases quotes={local.quotes} />}
				{!isEmpty(local.seal) && <app.Centenario label={local.seal} />}
			</app.Mobile>
		</app.Page>
	);
};

const Panel = ({ children }) => (
	<Scroll left={`55vw`} top={`15vh`} width={`40vw`} height={`85vh`} background={``}>
		<Stack alignment={`start`} background={``}>
			{children}
		</Stack>
	</Scroll>
);

const Title = styled.h1`
	font-size: 3.5vw;
	font-family: HelveticaNeueBold;
	font-weight: normal;
	line-height: 0.9;
	width: auto;
	height: auto;
	color: white;
`;

const TitleMobile = styled.h1`
	magin: 0;
	padding: 0;
	position: absolute;
	left: 10vw;
	top: 25vh;
	font-size: 8vw;
	font-family: HelveticaNeueBold;
	font-weight: normal;
	line-height: 0.9;
	width: 80vw;
	height: auto;
	color: white;
`;

const Content = styled.div`
	font-size: 1vw;
	line-height: 1.5;
	width: 35vw;
	height: auto;
	color: white;
	//   background-color: red;
	a,
	a:hover,
	a:visited {
		color: white;
		text-decoration: none;
		font-family: HelveticaNeueBlack;
	}
	h2 {
		font-size: 1vw;
		font-family: HelveticaNeueBlack;
		margin-top: 7vh;
	}
	p:first-child {
		margin-top: 0;
	}
`;

const News = styled.h3`
	font-size: 2.2vw;
	font-family: HelveticaNeueBold;
	line-height: 0.9;
	width: auto;
	height: auto;
	color: white;
	margin-bottom: 5vh;
`;

const Spacer = () => <Frame height={`13.8vh`} background={``} />;
