import React, { useContext, useState, useEffect, useRef } from 'react';
import { Frame, motion, Stack, Scroll } from 'framer';
import * as app from '../components';
import { ApplicationContext } from '../context';
import dompurify from 'dompurify';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';

export default () => {
	const [local, setLocal] = useState({ title: ``, news: [], quotes: [], meta: { title: ``, description: `` } });
	const { state } = useContext(ApplicationContext);

	useEffect(() => {
		if (!isEmpty(state)) {
			let _local = {};
			if (!isEmpty(state.data)) {
				_local = { ...state.data };
			}
			if (isEmpty(_local.meta)) {
				_local.meta = { title: ``, description: `` };
			}

			setLocal(_local);
			// reset the scroll on navigation
			window.scrollTo(0, 1);
		}
	}, [state]);

	return (
		<app.Page>
			<app.SEO title={local.meta.title} description={local.meta.description} url={local.url} />
			<app.Desktop>
				<Frame left={`50vw`} width={`50vw`} height={`100vh`} background={`white`} />
				<Scroll left={`50vw`} top={`12vh`} width={`45vw`} height={`87vh`} background={``}>
					<Stack alignment={`left`} width={`100%`} height={`auto`} style={{ marginLeft: `5vw`, marginRight: `5vw` }} background={``}>
						<div style={{ width: `5vw`, height: `2vh` }} />
						<Title>{local.title}</Title>
						<Content
							dangerouslySetInnerHTML={{ __html: dompurify.sanitize(local.content) }}
							style={{ fontSize: `1vw`, lineHeight: 1.5, width: `40vw`, height: `auto`, backgroundColor: `` }}
						/>
						<Frame height={`8vh`} background={``} />
					</Stack>
				</Scroll>
			</app.Desktop>
			<app.Mobile>
				<motion.div
					style={{
						position: `fixed`,
						bottom: 0,
						left: 0,
						width: `100vw`,
						height: `30vh`,
						background: `white`,
						opacity: 0,
					}}
					animate={{ opacity: 1, transition: { delay: 3 } }}
				/>

				<motion.div
					style={{
						position: `absolute`,
						y: `100vh`,
						width: `100vw`,
						height: `auto`,
						minHeight: `100vh`,
						backgroundColor: `white`,
						marginTop: `100vh`,
						boxSizing: `border-box`,
						padding: `10vw`,
						zIndex: `2vw`,
					}}
					animate={{ y: `-50vh`, transition: { type: `spring`, stiffness: 100, damping: 100, delay: 0.5 } }}>
					><TitleMobile>{local.title}</TitleMobile>
					<ContentMobile
						dangerouslySetInnerHTML={{ __html: dompurify.sanitize(local.content) }}
						style={{ fontSize: `4.5vw`, marginTop: `8vh`, lineHeight: 1.5, width: `80vw`, height: `auto`, backgroundColor: `` }}
					/>
				</motion.div>
				<div
					style={{
						position: `fixed`,
						bottom: 0,
						left: 0,
						width: `100vw`,
						height: `10vh`,
						backgroundImage: `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))`,
					}}></div>
			</app.Mobile>
		</app.Page>
	);
};

const Content = styled.div`
	font-size: 1vw;
	line-height: 1.5;
	width: 40vw;
	height: auto;
	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: black;
		font-family: HelveticaNeueBlack;
	}
	h2 {
		font-size: 1vw;
		font-family: HelveticaNeueBlack;
		margin-top: 7vh;
	}
	h2:first-child {
		margin-top: 0;
	}
`;

const ContentMobile = styled.div`
	font-size: 1vw;
	line-height: 1.5;
	width: 40vw;
	height: auto;
	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: black;
		font-family: HelveticaNeueBlack;
	}
	h2 {
		font-size: 5vw;
		font-family: HelveticaNeueBlack;
		margin-top: 7vh;
	}
	h2:first-child {
		margin-top: 0;
	}
`;

const Title = styled.h1`
	font-size: 3.5vw;
	font-family: HelveticaNeueBold;
	font-weight: normal;
	line-height: 0.9;
	width: 100%;
	height: 15vh;
`;

const TitleMobile = styled.h1`
	margin: 0;
	padding: 0;
	font-size: 8vw;
	font-family: HelveticaNeueBold;
	font-weight: normal;
	line-height: 0.9;
	width: 100%;
	// height: 15vh;
`;
