import React, { useContext } from 'react';
import { Frame } from 'framer';
import * as app from '.';
import assets from '../assets';
import { ApplicationContext } from '../context';

export default () => {
	const { dispatch } = useContext(ApplicationContext);
	return (
		<React.Fragment>
			<app.Desktop>
				<Frame
					left={`2.2vw`}
					top={`5vh`}
					width={`auto`}
					height={`auto`}
					background={``}
					onTap={() => dispatch({ type: `REQUEST_NAVIGATION`, url: `/` })}>
					<img src={assets.Marca} style={{ width: `6vw` }} />
				</Frame>
			</app.Desktop>
			<app.Mobile>
				<Frame
					left={`10vw`}
					top={`5vh`}
					width={`auto`}
					height={`auto`}
					background={``}
					style={{ position: `fixed`, zIndex: 1 }}
					onTap={() => dispatch({ type: `REQUEST_NAVIGATION`, url: `/` })}>
					<img src={assets.Marca} style={{ width: `17vw` }} />
				</Frame>
			</app.Mobile>
		</React.Fragment>
	);
};
