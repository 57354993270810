import React, { useState } from 'react';
import { Frame } from 'framer';
import * as app from '.';
import assets from '../assets';

export default ({ label }) => {
	const [visible, setVisible] = useState(false);
	return (
		<React.Fragment>
			<app.Desktop>
				<Frame
					left={`2.2vw`}
					bottom={`5vh`}
					width={`auto`}
					height={`auto`}
					background={``}
					onHoverStart={() => setVisible(true)}
					onHoverEnd={() => setVisible(false)}>
					<img src={assets.MarcaCentenario} style={{ width: `4vw` }} />
				</Frame>
				<Frame
					left={`2.2vw`}
					bottom={`15vh`}
					width={`auto`}
					height={`auto`}
					backgroundColor={`black`}
					style={{ color: `white`, padding: `1.5vw`, fontSize: `0.7vw`, fontFamily: `HelveticaNeueMedium` }}
					variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 }, transition: { duration: 0.1, ease: `linear` } }}
					initial={`hidden`}
					animate={visible ? `visible` : `hidden`}>
					<span>{label}</span>
				</Frame>
			</app.Desktop>
			<app.Mobile>
				<Frame left={`10vw`} bottom={`5vh`} width={`auto`} height={`auto`} background={``} onTap={() => setVisible(!visible)}>
					<img src={assets.MarcaCentenario} style={{ width: `15vw` }} />
				</Frame>
				<Frame
					left={`10vw`}
					bottom={`15vh`}
					width={`80vw`}
					height={`auto`}
					backgroundColor={`black`}
					style={{ color: `white`, padding: `5vw`, fontSize: `3.5vw`, fontFamily: `HelveticaNeueMedium`, boxSizing: `border-box` }}
					variants={{ visible: { opacity: 1 }, hidden: { opacity: 0 }, transition: { duration: 0.1, ease: `linear` } }}
					initial={`hidden`}
					animate={visible ? `visible` : `hidden`}>
					<span>{label}</span>
				</Frame>{' '}
			</app.Mobile>
		</React.Fragment>
	);
};
