import React, { useEffect, useState, useContext } from 'react';
import { Frame, Stack, Page } from 'framer';
import * as app from '.';
import { ApplicationContext } from '../context';
import isEmpty from 'lodash/isEmpty';

export default () => {
	const { state } = useContext(ApplicationContext);
	const [currentQuote, setCurrentQuote] = useState(0);

	useEffect(() => {
		if (!isEmpty(state.data)) {
			const interval = setInterval(() => {
				setCurrentQuote((quote) => {
					let newQuote = quote + 1;
					return state.data && state.data.quotes && newQuote === state.data.quotes.length ? 0 : newQuote;
				});
			}, 12000);
			return () => clearInterval(interval);
		}
	}, [state]);

	return (
		<React.Fragment>
			<app.Desktop>
				<Page
					alignment={`start`}
					left={`5vw`}
					top={`35vh`}
					width={`40vw`}
					height={`50vh`}
					dragEnabled={false}
					background={``}
					animateCurrentPageUpdate={false}
					currentPage={currentQuote}>
					{state.data &&
						state.data.quotes &&
						state.data.quotes.map((entry, index) => (
							<Stack
								key={index}
								animate={index === currentQuote ? `enter` : `exit`}
								initial={`exit`}
								background={``}
								variants={{
									enter: {
										opacity: [0, 1, 1, 0],
										transition: {
											duration: 1,
											ease: `antecipate`,
											opacity: { times: [0, 0.1, 0.9, 1], duration: 12 },
										},
									},
									exit: { opacity: 0, transition: { duration: 0 } },
								}}>
								<Frame
									width='100%'
									height={`auto`}
									style={{
										color: `white`,
										fontSize: `1.95vw`,
										fontFamily: `HelveticaNeueThin`,
										lineHeight: 1.5,
									}}
									background={``}>
									<span>{entry.quote}</span>
								</Frame>
								{!isEmpty(entry.source.origin) && !isEmpty(entry.source.date) && (
									<Frame width={`100%`} height={`auto`} background={``} style={{ marginTop: `3vh`, color: `white` }}>
										<span>
											<span style={{ fontFamily: `HelveticaNeueBlack` }}>{entry.source.origin}</span>– {entry.source.date}
										</span>
									</Frame>
								)}
							</Stack>
						))}
				</Page>
			</app.Desktop>
			<app.Mobile>
				<Page
					alignment={`start`}
					left={`10vw`}
					top={`35vh`}
					width={`80vw`}
					height={`50vh`}
					dragEnabled={false}
					background={``}
					animateCurrentPageUpdate={false}
					currentPage={currentQuote}>
					{state.data &&
						state.data.quotes &&
						state.data.quotes.map((entry, index) => (
							<Stack
								key={index}
								animate={index === currentQuote ? `enter` : `exit`}
								initial={`exit`}
								background={``}
								variants={{
									enter: {
										opacity: [0, 1, 1, 0],
										transition: {
											duration: 1,
											ease: `antecipate`,
											opacity: { times: [0, 0.1, 0.9, 1], duration: 12 },
										},
									},
									exit: { opacity: 0, transition: { duration: 0 } },
								}}>
								<Frame
									width='100%'
									height={`auto`}
									style={{
										color: `white`,
										fontSize: `4.8vw`,
										fontFamily: `HelveticaNeueThin`,
										lineHeight: 1.5,
									}}
									background={``}>
									<span>{entry.quote}</span>
								</Frame>
								{!isEmpty(entry.source.origin) && !isEmpty(entry.source.date) && (
									<Frame width={`100%`} height={`auto`} background={``} style={{ marginTop: `3vh`, color: `white`, fontSize: `3.5vw` }}>
										<span>
											<span style={{ fontFamily: `HelveticaNeueBlack` }}>{entry.source.origin}</span>– {entry.source.date}
										</span>
									</Frame>
								)}
							</Stack>
						))}
				</Page>
			</app.Mobile>
		</React.Fragment>
	);
};

const Phrase = () => {};
