import React from 'react';
import { Frame, motion } from 'framer';

export default ({ children }) => {
	return (
		<motion.div style={{ width: `100vw`, height: `calc(var(--vh, 1vh) * 100)` }} {...transition}>
			{children}
		</motion.div>
	);
};

const transition = {
	variants: {
		enter: {
			opacity: 1,
			transition: { duration: 1 },
		},
		exit: {
			opacity: 0,
			transition: { duration: 5 },
		},
	},
	animate: `enter`,
	exit: `exit`,
	initial: `exit`,
};
