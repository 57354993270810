import React, { useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import axios from 'axios';

const ApplicationContext = React.createContext();

const { Provider, Consumer } = ApplicationContext;

const ApplicationProvider = ({ children }) => {
	const gat = process.env.REACT_APP_SEO_GA;
	const history = useHistory();
	const [state, dispatch] = useReducer(reducer, initial);

	useEffect(() => {
		ReactGA.initialize(gat, { testMode: process.env.NODE_ENV !== `production` });
		axios.interceptors.request.use(config => ({
			...config,
			onDownloadProgress: event => {
				const { loaded, total } = event;
				dispatch({
					type: `ON_NAVIGATION_DATA_FETCH_PROGRESS`,
					progress: total > 0 ? loaded / total : 0
				});
			}
		}));
	}, [gat]);

	useEffect(() => {
		// the async fetch function
		const fetch = async ({ url, locale }) => {
			const storeKey = `${locale}${url}`;
			if (!isEmpty(state.data[storeKey])) {
				const data = state.store[storeKey];
				dispatch({ type: `ON_NAVIGATION_DATA_FETCH_SUCCESS`, url, data });
			} else {
				try {
					const endpoint = process.env.NODE_ENV === `development` ? process.env.REACT_APP_PROXY : process.env.PUBLIC_URL;
					const target = `${endpoint}${isMain(url) ? `/${locale}/main` : url}.json`;
					console.log(`willfetch`, target, `url`, url);
					const result = await axios.get(target);
					if (result.status === 200) {
						const { data } = result;
						dispatch({ type: `ON_NAVIGATION_DATA_FETCH_SUCCESS`, url, data });
					}
				} catch (error) {
					console.error(error);
					dispatch({ type: `ON_NAVIGATION_DATA_FETCH_FAILURE`, url, error });
				}
			}
		};

		// destruct the values
		const { status, url, locale, data } = state;

		// handle the status
		if (status === `PENDING_NAVIGATION_DATA` || status === `FIRST`) {
			let target = status === `FIRST` ? history.location.pathname : url;
			if (!isLocalePresent(target)) {
				target = `/${locale}${url}`;
			}
			const targetLocale = parseLocale(target);

			dispatch({ type: `REQUEST_NAVIGATION_DATA`, locale: targetLocale });
			fetch({ url: target, locale: targetLocale });
		} else if (status === `PENDING_NAVIGATION`) {
			// const target = data;
			let target = isLocalePresent(url) ? url : `${locale}${url}`;
			history.push(target, { ...data });
			ReactGA.set({ page: url }); // Update the user's current page
			ReactGA.pageview(url); // Record a pageview for the given page

			dispatch({ type: `ON_NAVIGATION_DONE` });
		}

		// error handling
	}, [state, history, dispatch]);

	return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

const isLocalePresent = target => {
	return target.indexOf(`/pt`) === 0 || target.indexOf(`/en`) === 0;
};

const isMain = url => {
	return url === `/pt` || url === `/pt/` || url === `/en` || url === `/en/`;
};

const parseLocale = url => {
	let result = `pt`;
	console.log(`parseLocale`, url);
	if (url.indexOf(`/en`) === 0) {
		result = `en`;
	}

	return result;
};

export { ApplicationProvider, Consumer as ApplicationConsumer, ApplicationContext };

const reducer = (state, action) => {
	// console.log(state, action);
	switch (action.type) {
		case `REQUEST_NAVIGATION`: {
			const status = `PENDING_NAVIGATION_DATA`;
			const { url } = action;

			return { ...state, status, url };
		}
		case `REQUEST_NAVIGATION_DATA`: {
			const status = `LOADING_NAVIGATION_DATA`;
			const progress = 0;
			const { locale } = action;
			const error = null;

			return { ...state, status, error, locale, progress };
		}
		case `ON_NAVIGATION_DATA_FETCH_PROGRESS`: {
			const { progress } = action;
			return { ...state, progress };
		}
		case `ON_NAVIGATION_DATA_FETCH_SUCCESS`: {
			const status = `PENDING_NAVIGATION`;
			let { data, store, locale, navigation } = state;
			const { url, data: _data } = action;
			const progress = 1;
			store[`${locale}${url}`] = { ..._data };
			data = { ..._data };
			const target = findIndex(navigation, o => o.url === url.replace(`/${locale}`, ``));
			navigation.map((item, index) => (item.selected = index === target));
			const theme = target > -1 ? navigation[target].theme : `dark`;

			return { ...state, data, store, status, url, navigation, theme, progress };
		}
		case `ON_NAVIGATION_DATA_FETCH_FAILURE`: {
			const status = `PENDING_ERROR_DISPLAY`;
			const { error } = action;

			return { ...state, error, status };
		}
		case `ON_NAVIGATION_DONE`: {
			const status = `IDLE`;

			return { ...state, status };
		}
		case `REQUEST_NEW_LOCALE`: {
			const { locale } = action;
			const { url: current } = state;
			const url = `${current.replace(/(pt|en)\/?/, ``)}`;

			const status = `PENDING_NAVIGATION_DATA`;

			return { ...state, status, url, locale };
		}
		default: {
			return state;
		}
	}
};

const initial = {
	data: {},
	store: {},
	status: `FIRST`,
	url: ``,
	progress: 0,
	locale: `pt`,
	error: null,
	theme: `dark`,
	navigation: [
		{ label: { pt: `Biografia`, en: `Biography` }, url: `/biografia`, theme: `ligth`, selected: false },
		{
			label: { pt: `Livro-obra`, en: `Livro-obra` },
			url: `/livro-obra`,
			theme: `light`,
			selected: false
		},
		{ label: { pt: `Serviços`, en: `Services` }, url: `/servicos`, theme: `light`, selected: false }
	]
};
