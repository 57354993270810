import Marca from './marca.svg';
import MarcaCentenario from './lc-100.svg';
import Background from './background-1.jpg';
import ChevronOpen from './chevron-open.svg';
import ChevronClose from './chevron-close.svg';

export default {
	Marca,
	MarcaCentenario,
	Background,
	ChevronOpen,
	ChevronClose
};
