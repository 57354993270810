import React, { useState, useRef, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Frame, useAnimation } from 'framer';
import * as app from '.';
import assets from '../assets';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { ApplicationContext } from '../context';

export default React.memo(() => {
	const { state } = useContext(ApplicationContext);
	const [local, setLocal] = useState({ slides: [] });
	const [dimm, setDimm] = useState(false);

	useEffect(() => {
		if (!isEmpty(state.data) && !isEmpty(state.data.background)) {
			if (!isEqual(local.slides, state.data.background)) {
				const slides = [...state.data.background];
				setLocal({ slides });
			}
		}
		setDimm(state.url === `/${state.locale}` || state.url === `/${state.locale}/`);
	}, [state]);

	return (
		<div style={{ position: `relative`, zIndex: 0, left: 0, top: 0 }}>
			<app.Desktop>
				<Background mobile={false} theme={state.theme} />
				<Slideshow mobile={false} slides={local.slides} width={`50vw`} height={`100vh`} duration={5} />
				<Dimmer dimm={dimm} mobile={false} />
			</app.Desktop>
			<app.Mobile>
				<Background mobile={true} />
				<Slideshow mobile={true} slides={local.slides} width={`100vw`} height={`calc(var(--vh, 1vh) * 100)`} duration={5} />
				<Dimmer dimm={dimm} mobile={true} />
			</app.Mobile>
		</div>
	);
});

const Dimmer = ({ dimm, mobile }) => (
	<Frame
		width={mobile ? `100vw` : `50vw`}
		height={mobile ? `calc(var(--vh, 1vh) * 100)` : `100vh`}
		background={`black`}
		animate={dimm ? `dimmed` : `clear`}
		initial={`dimmed`}
		variants={{ clear: { opacity: 0.2 }, dimmed: { opacity: 0.6 } }}
		style={{ position: `fixed`, top: 0, zIndex: 2 }}
	/>
);

const Background = ({ mobile, theme }) => (
	<React.Fragment>
		<Frame
			width={mobile ? `100vw` : `50vw`}
			height={mobile ? `calc(var(--vh, 1vh) * 100)` : `100vh`}
			background={``}
			image={assets.Background}
			style={{ backgroundPosition: `center center`, position: `fixed` }}
		/>
		{!mobile && (
			<Frame
				x={`50vw`}
				width={`50vw`}
				height={mobile ? `calc(var(--vh, 1vh) * 100)` : `100vh`}
				background={theme === `dark` ? `black` : `white`}
				style={{ position: `fixed` }}
			/>
		)}
	</React.Fragment>
);

const Slideshow = ({ slides, width, height, mobile, duration = 8000 }) => {
	const [index, setIndex] = useState(-1);
	const [state, setState] = useState({ A: ``, B: `` });

	const next = () => {
		const nextindex = index + 1;
		if (nextindex === slides.length) {
			setIndex(0);
		} else {
			setIndex(nextindex);
		}
	};

	useEffect(() => {
		if (slides && slides.length > 0) {
			setTimeout(() => {
				next();
			}, duration * 1000);
		}
	}, [slides]);

	useEffect(() => {
		if (index % 2 === 0) {
			//par
			setState((state) => ({ ...state, A: slides[index] }));
		} else {
			//impar
			setState((state) => ({ ...state, B: slides[index] }));
		}
	}, [index]);

	return (
		<Frame width={width} height={height} background={``} overflow={`hidden`} style={{ position: mobile ? `fixed` : `absolute`, zIndex: 1 }}>
			<Slide source={state.A} presented={next} duration={duration} center />
			<Slide source={state.B} presented={next} duration={duration} center />
		</Frame>
	);
};

const Slide = ({ source, presented, duration }) => {
	const [currentSource, setCurretSource] = useState(``);
	const [currentZIndex, setCurrentZIndex] = useState(0);
	const [done, setDone] = useState(false);

	const time = useRef(-1);
	const controls = useAnimation();

	const prepare = async () => {
		await controls.start({ opacity: 0, transition: { duration: 0 } });
		setDone(false);
		setCurretSource(source);
		setCurrentZIndex(currentZIndex + 2);
	};

	const play = async () => {
		await controls.start({
			opacity: [0, 1],
			transition: { duration: 2, delay: 0.5 },
		});
		setDone(true);
	};

	useEffect(() => {
		if (!isEmpty(source)) {
			prepare();
		}
	}, [source]);

	useEffect(() => {
		if (done) {
			time.current = setTimeout(() => {
				presented();
			}, duration * 1000);
		}
		return () => clearTimeout(time.current);
	}, [done]);

	return (
		<Frame
			opacity={0}
			animate={controls}
			size={`100%`}
			background={``}
			style={{
				zIndex: currentZIndex,
				position: `absolute`,
				display: `flex`,
				alignItems: `center`,
				justifyContent: `center`,
			}}>
			{!isEmpty(currentSource) && (
				<React.Fragment>
					<img src={currentSource} onLoad={play} style={{ width: `100%`, height: `100%`, objectFit: `cover` }} />
				</React.Fragment>
			)}
		</Frame>
	);
};
