import React, { useContext, useEffect, useState } from 'react';
import { Frame, motion, AnimatePresence } from 'framer';
import { ApplicationContext } from '../context';
import styled from 'styled-components';

export default () => {
	const { state } = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(state.progress < 1);
	}, [state]);

	return (
		<AnimatePresence>
			{isLoading ? (
				<motion.div
					style={{ position: `absolute`, left: 0, top: 0, width: `100vw`, height: `calc(var(--vh, 1vh) * 100)`, zIndex: 1000 }}
					variants={{
						visible: { opacity: 1, transition: { duration: 0.2, delay: 1 } },
						hidden: { opacity: 0, transition: { duration: 0.2, delay: 2 } },
					}}
					initial={`hidden`}
					exit={`hidden`}
					animate={`visible`}>
					<Frame size={`100%`} background={`black`} opacity={0.8} />
					<div className={`loader rotating-plane`} />
				</motion.div>
			) : null}
		</AnimatePresence>
	);
};
