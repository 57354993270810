import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApplicationProvider } from './application/context';
import Application from './application';

import './index.css';

ReactDOM.render(
	<Router basename={process.env.PUBLIC_URL}>
		<ApplicationProvider>
			<Application />
		</ApplicationProvider>
	</Router>,
	document.getElementById('root')
);

serviceWorker.register();
