import React from 'react';
import { Helmet } from 'react-helmet';
export default ({ title = ``, url = ``, description = `` }) => {
	return (
		<Helmet>
			<meta charSet='utf-8' />
			<title>{title}</title>
			<link rel='canonical' href={`${process.env.REACT_APP_SEO_URL}${url}`} />
			<script type='application/ld+json'>
				{JSON.stringify({
					'@context': 'http://schema.org',
					'@graph': [
						{
							'@type': 'Organization',
							'@id': process.env.REACT_APP_SEO_ID,
							url: process.env.REACT_APP_SEO_URL,
							name: process.env.REACT_APP_SEO_NAME
						},
						{
							'@type': 'WebSite',
							'@id': process.env.REACT_APP_SEO_ID,
							url: process.env.REACT_APP_SEO_URL,
							name: process.env.REACT_APP_SEO_NAME,
							publisher: { '@id': process.env.REACT_APP_SEO_ID }
						},
						{
							'@type': 'WebPage',
							'@id': process.env.REACT_APP_SEO_ID,
							url: process.env.REACT_APP_SEO_URL,
							inLanguage: process.env.REACT_APP_SEO_MAIN_LANGUAGE,
							name: 'Home',
							isPartOf: { '@id': process.env.REACT_APP_SEO_ID },
							datePublished: '2013-03-11T21:35:34+00:00',
							dateModified: '2014-04-17T18:52:24+00:00',
							about: { '@id': process.env.REACT_APP_SEO_ID },
							description: description
						}
					]
				})}
			</script>
			<meta property='og:title' content={process.env.REACT_APP_SEO_NAME} />
			<meta property='og:description' content={description} />
			<meta property='og:image' content={`${process.env.REACT_APP_SEO_URL}/${process.env.REACT_APP_SEO_CARD}`} />
			<meta property='og:url' content={process.env.REACT_APP_SEO_URL} />
			<meta property='og:site_name' content={process.env.REACT_APP_SEO_NAME} />
			<meta property='fb:app_id' content={process.env.REACT_APP_SEO_FB_APP_ID} />
			<meta name='twitter:card' content={`${process.env.REACT_APP_SEO_URL}/${process.env.REACT_APP_SEO_CARD}`} />
			<meta name='twitter:image:alt' content={process.env.REACT_APP_SEO_CARD_ALT} />
			<meta name='twitter:site' content={process.env.REACT_APP_SEO_TW_USER_ID} />
			<link rel='icon' href={`${process.env.PUBLIC_URL}/favicon.ico`} />
			<link rel='manifest' href={`${process.env.PUBLIC_URL}/manifest.json`} />
			<meta name='apple-mobile-web-app-capable' content='yes' />
			<meta name='apple-mobile-web-app-status-bar-style' content='black' />
			<meta name='apple-mobile-web-app-title' content={process.env.REACT_APP_SEO_NAME} />
			<link rel='apple-touch-icon' href='logo192.png' />
			<link
				rel='apple-touch-startup-image'
				href={`${process.env.PUBLIC_URL}/assets/Default-568h@2x~iphone.png`}
				media='(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
			/>
			<link
				rel='apple-touch-startup-image'
				href={`${process.env.PUBLIC_URL}/assets/Default-667h.png`}
				media='(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)'
			/>
			<link
				rel='apple-touch-startup-image'
				href={`${process.env.PUBLIC_URL}/assets/Default-736h.png`}
				media='(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)'
			/>
			<link
				rel='apple-touch-startup-image'
				href={`${process.env.PUBLIC_URL}/assets/Default-Portrait@2x~ipad.png`}
				media='(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)'
			/>
			<link
				rel='apple-touch-startup-image'
				href={`${process.env.PUBLIC_URL}/assets/Default-Portrait@~ipadpro.png`}
				media='(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)'
			/>

			<title>{process.env.REACT_APP_SEO_NAME}</title>
		</Helmet>
	);
};
