import React, { useState, useEffect, useContext, useRef } from 'react'
import { Frame, Stack, motion } from 'framer'
import { useHistory } from 'react-router-dom'
import { ApplicationContext } from '../context'
import Menu from '../assets/menu.svg'
import Close from '../assets/close.svg'

import * as app from '.'

export default () => {
  const currentURL = useRef('')
  const { dispatch, state } = useContext(ApplicationContext)
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const [dark, setDark] = useState(true)

  useEffect(() => {
    window.onscroll = e => {
      const current = window.scrollY
      const limit = 0.4236453202 * window.innerHeight
      setDark(current <= limit)
    }
  }, [])

  const go = url => {
    dispatch({ type: `REQUEST_NAVIGATION`, url: `${url}` })
    setIsNavigationOpen(false)
  }

  const switchLanguage = () => {
    let translated
    if (state.url.indexOf(`/pt`) === -1) {
      translated = state.url.replace(`/en`, `/pt`)
    } else {
      translated = state.url.replace(`/pt`, `/en`)
    }
    dispatch({ type: `REQUEST_NAVIGATION`, url: translated })
    setTimeout(() => {
      setIsNavigationOpen(false)
    }, 500)
  }

  return (
    <React.Fragment>
      <app.Desktop>
        <Stack
          left={`55vw`}
          top={`5vh`}
          width={`auto`}
          height={`auto`}
          gap={40}
          direction={`horizontal`}
          background={``}
          style={{ pointerEvents: `auto` }}
        >
          {state.navigation.map((item, i) => (
            <Item
              key={item.url}
              i={i}
              {...item}
              locale={state.locale}
              theme={state.theme}
              onTap={go}
              whileTap={{ scale: 1.1 }}
              whileHover={{ cursor: `pointer` }}
            />
          ))}
        </Stack>
      </app.Desktop>
      <app.Mobile>
        <motion.div
          style={{
            position: `fixed`,
            top: 0,
            left: 0,
            width: `100vw`,
            height: `100vh`,
            overflow: `hidden`,
            pointerEvents: `none`
          }}
        >
          <OpenClose
            dark={dark}
            open={isNavigationOpen}
            onStateChange={state => setIsNavigationOpen(state)}
          />
          <Drawer
            open={isNavigationOpen}
            items={state.navigation}
            locale={state.locale}
            go={go}
            onSwitchLanguage={switchLanguage}
          />
        </motion.div>
      </app.Mobile>
    </React.Fragment>
  )
}

const OpenClose = ({ dark, open, onStateChange }) => {
  return (
    <motion.div
      style={{
        position: `absolute`,
        right: open ? `5vw` : `10vw`,
        top: open ? `10vw` : `10vw`,
        width: `10vw`,
        height: `10vw`,
        zIndex: 1000,
        pointerEvents: `auto`
      }}
      onTap={() => {
        onStateChange(open ? false : true)
      }}
    >
      {open ? (
        <img src={Close} style={{ width: `7vw` }} />
      ) : (
        <img src={Menu} style={{ width: `10vw` }} />
      )}
    </motion.div>
  )
}

// const OpenClose = ({ dark, open, onStateChange }) => {
//   return (
//     <motion.div
//       style={{
//         position: `absolute`,
//         right: open ? `5vw` : `10vw`,
//         top: open ? `10vw` : `10vw`,
//         width: `10vw`,
//         height: `10vw`,
//         zIndex: 1000,
//         scale: open ? 1 : 0.7,
//         pointerEvents: `auto`,
//         backgroundColor: `red`
//       }}
//       onTap={() => {
//         onStateChange(open ? false : true)
//       }}
//     >
//       {open ? (
//         <svg>
//           <g style={{ fill: `white` }}>
//             <rect
//               x='0'
//               y='9.2'
//               transform='matrix(0.7071 0.7071 -0.7071 0.7071 11.1464 -4.9099)'
//               width='23'
//               height='3.5'
//             />
//             <rect
//               x='0'
//               y='9.2'
//               transform='matrix(0.7071 -0.7071 0.7071 0.7071 -4.4099 11.3536)'
//               width='23'
//               height='3.5'
//             />
//           </g>
//         </svg>
//       ) : (
//         <svg>
//           <g style={{ fill: dark ? `white` : `black` }}>
//             <rect x='3' y='3' width='22.1' height='4' />
//             <rect x='3' y='11' width='39' height='4' />
//             <rect x='3' y='19' width='31.1' height='4' />
//           </g>
//         </svg>
//       )}
//     </motion.div>
//   )
// }

const Drawer = ({ open, items, locale, go, onSwitchLanguage }) => {
  const anim = {
    variants: {
      open: { x: `0vw` },
      close: { x: `100vw` }
    },
    transition: { type: `spring`, stiffness: 500, damping: 100 },
    initial: `cloase`
  }
  return (
    <motion.div
      {...anim}
      animate={open ? `open` : `close`}
      style={{
        position: `absolute`,
        boxSizing: `border-box`,
        x: `100vw`,
        y: 0,
        width: `100vw`,
        height: `calc(var(--vh, 1vh) * 100)`,
        backgroundColor: `black`,
        padding: `10vw`,
        paddingTop: `30vw`,
        pointerEvents: `none`
      }}
    >
      {items &&
        items.map((item, i) => {
          return (
            <motion.div
              key={i}
              style={{
                color: `white`,
                fontFamily: `HelveticaNeue`,
                fontSize: `7vw`,
                marginTop: `10vw`,
                pointerEvents: `auto`
              }}
              onTap={() => go(item.url)}
            >
              {item.label[locale]}
            </motion.div>
          )
        })}
      <motion.div
        style={{
          color: `#D1D1D1`,
          fontFamily: `HelveticaNeue`,
          fontSize: `4vw`,
          marginTop: `15vh`,
          pointerEvents: `auto`
        }}
        onTap={() => onSwitchLanguage()}
      >
        {locale === `pt`
          ? `Swtich content to english`
          : `Trocar o conteúdo para português`}
      </motion.div>
    </motion.div>
  )
}

const Item = ({ label, url, onTap, whileTap, theme, selected, locale, i }) => {
  return (
    <Frame
      onTap={() => onTap(url)}
      whileTap={whileTap}
      background={``}
      width={`auto`}
      height={`100%`}
      style={{
        display: `flex`,
        flexDirection: `row`,
        alignItems: `center`,
        justifyContent: `center`,
        fontSize: `1vw`,
        color: theme === `dark` ? `white` : `black`,
        fontFamily: selected ? `HelveticaNeueBlack` : `HelveticaNeue`
      }}
    >
      <span>{label[locale]}</span>
    </Frame>
  )
}
