import React, { useContext, useState, useEffect } from 'react';
import { Frame, Stack, Scroll, motion } from 'framer';
import { ApplicationContext } from '../context';
import dompurify from 'dompurify';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import assets from '../assets';
import * as app from '../components';

export default () => {
	const [local, setLocal] = useState({
		title: ``,
		news: [],
		quotes: [],
		meta: { title: ``, description: `` },
	});
	const [modal, setModal] = useState(``);
	const { state } = useContext(ApplicationContext);

	useEffect(() => {
		if (!isEmpty(state)) {
			let _local = {};

			if (!isEmpty(state.data)) {
				_local = { ...state.data };
			}

			if (isEmpty(_local.meta)) {
				_local.meta = { title: ``, description: `` };
			}

			if (!isEmpty(_local.timeline) && !isEmpty(_local.timeline_titles)) {
				const basic = [`date`, `local`, `info`, `extended`, `images`];
				const timeline = [..._local.timeline];
				const parsed = timeline.map((entry) => {
					const extended = [];
					for (let key in entry) {
						if (!basic.includes(key) && key.indexOf(`img`) === -1 && entry[key] !== ``) {
							extended.push({
								title: _local.timeline_titles[key],
								content: entry[key].replace(/\*(.*?)\*/g, '<i>$1</i>').replace(/\/\//g, `<br/>`),
							});
						}
						if (key.toLowerCase() === `obras` && entry[key] === ``) {
							extended.push({
								title: _local.timeline_titles[key],
								content: ``,
							});
						}
					}
					const images = [];
					const endpoint = process.env.NODE_ENV === `development` ? process.env.REACT_APP_PROXY : process.env.PUBLIC_URL;

					for (let key in entry) {
						if (!basic.includes(key) && key.indexOf(`img`) !== -1 && entry[key] !== ``) {
							images.push(`${endpoint}/assets/biografia/${entry[key]}`);
						}
					}

					if (!isEmpty(images)) {
						entry.images = images;
					}

					for (let key in entry) {
						if (!basic.includes(key)) {
							delete entry[key];
						}
					}

					if (!isEmpty(extended)) {
						entry.extended = extended;
					}

					if (entry.info) {
						entry.info = entry.info.replace(/\*(.*?)\*/g, '<i>$1</i>').replace(/\/\//g, `<br/>`);
					}

					return entry;
				});

				_local.timeline = parsed;
			}
			setLocal(_local);
		}
	}, [state]);

	const onTap = (src) => {
		if (!isEmpty(src)) {
			setModal(src);
		}
	};

	return (
		<app.Page>
			<app.SEO title={local.meta.title} description={local.meta.description} url={local.url} />
			<app.Desktop>
				<Frame left={`50vw`} width={`50vw`} height={`100vh`} background={`white`} />
				<Scroll left={`50vw`} top={`12vh`} width={`45vw`} height={`87vh`} background={``}>
					<Stack alignment={`left`} width={`100%`} height={`auto`} style={{ marginLeft: `5vw`, marginRight: `5vw` }} background={``}>
						<div style={{ width: `5vw`, height: `2vh` }} />
						<Title>{local.title}</Title>
						<Content>{local.content}</Content>
						<Frame height={`2vh`} background={``} />
						{local.timeline && <Bio timeline={local.timeline} onTap={onTap} />}
						<Frame height={`2vh`} background={``} />
						{local.exhibitions && <Exhibitions exhibitions={local.exhibitions} exhibitionsTitles={local.exhibitions_titles} />}
						<Frame height={`8vh`} background={``} />
					</Stack>
				</Scroll>
				{!isEmpty(modal) && <Modal data={modal} onTap={() => setModal(``)} />}
			</app.Desktop>
			<app.Mobile>
				<motion.div
					style={{
						position: `fixed`,
						bottom: 0,
						left: 0,
						width: `100vw`,
						height: `30vh`,
						background: `white`,
						opacity: 0,
					}}
					animate={{ opacity: 1, transition: { delay: 3 } }}
				/>

				<Stack
					style={{
						position: `absolute`,
						y: `100vh`,
						width: `100vw`,
						height: `auto`,
						minHeight: `100vh`,
						backgroundColor: `white`,
						marginTop: `100vh`,
						zIndex: `2vw`,
						paddingLeft: `10vw`,
					}}
					alignment={`start`}
					width={`100vw`}
					height={`auto`}
					background={`white`}
					animate={{ y: `-50vh`, transition: { type: `spring`, stiffness: 100, damping: 100, delay: 0.5 } }}>
					<Frame height={`5vh`} background={``} />
					<TitleMobile>{local.title}</TitleMobile>
					<Frame height={`2vh`} background={``} />
					{local.timeline && <Bio timeline={local.timeline} onTap={onTap} mobile={true} />}
					<Frame height={`2vh`} background={``} />
					{local.exhibitions && <Exhibitions exhibitions={local.exhibitions} exhibitionsTitles={local.exhibitions_titles} mobile={true} />}
					<Frame height={`8vh`} background={``} />{' '}
				</Stack>
				<div
					style={{
						position: `fixed`,
						bottom: 0,
						left: 0,
						width: `100vw`,
						height: `10vh`,
						backgroundImage: `linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))`,
					}}></div>
				{!isEmpty(modal) && <Modal mobile={true} data={modal} onTap={() => setModal(``)} />}
			</app.Mobile>
		</app.Page>
	);
};

const Modal = ({ data, onTap, mobile = false }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const side = mobile ? `80vw` : `40vw`;
	return (
		<Frame size={`100%`} animate={{ opacity: 1 }} background={``} onTap={onTap} style={{ position: `fixed`, zIndex: 2000 }}>
			<Frame size={`100%`} background={`black`} opacity={0.5} />
			<Frame
				size={`auto`}
				background={``}
				center
				initial={`initial`}
				variants={{
					loaded: {
						opacity: 1,
						scale: 1,
						transition: { delay: 0.1, ease: `antecipate` },
					},
					initial: { opacity: 0, scale: 0.9 },
				}}
				animate={isLoaded ? `loaded` : `initial`}>
				<img src={data} onLoad={() => setIsLoaded(true)} style={{ width: side, height: side, objectFit: `contain` }} />
			</Frame>
		</Frame>
	);
};

const Bio = React.memo(({ timeline, onTap, close, mobile }) => {
	return (
		<Stack alignment={`start`} width={mobile ? `80vw` : `100vw`} height={`auto`} gap={40} onTap={close} background={`white`}>
			{timeline.map((item, index) => (
				<BioTimelineItem key={index} item={item} onTap={onTap} mobile={mobile} />
			))}
		</Stack>
	);
});

const BioTimelineItem = ({ item, onTap, mobile }) => {
	const [open, setOpen] = useState(false);

	return (
		<Stack alignment={`start`} width={`100%`} height={`auto`}>
			<h3 style={{ fontFamily: `HelveticaNeueBold`, fontSize: mobile ? `5vw` : `1.2vw` }}>{item.date}</h3>
			<p style={{ fontFamily: `HelveticaNeueThin`, fontSize: mobile ? `4vw` : `0.9vw` }}>{item.local}</p>
			<p
				style={{
					fontFamily: `HelveticaNeue`,
					fontSize: mobile ? `4vw` : `1vw`,
					lineHeight: 1.5,
				}}
				dangerouslySetInnerHTML={{ __html: dompurify.sanitize(item.info) }}>
				{/* {item.info} */}
			</p>
			{((item.extended && item.extended.length > 0) || (item.images && item.images.length > 0)) && (
				<ExtendedItemButton mobile={mobile} open={open} toggle={() => setOpen(!open)} />
			)}
			{open &&
				item.extended &&
				item.extended.length > 0 &&
				item.images &&
				item.images.length > 0 &&
				item.extended.map((item, index) => {
					return item.title === `Obras` ? <ExtendedItem key={index} mobile={mobile} {...item} /> : null;
				})}
			{open && item.images && <BioTimelineItemGallery images={item.images} mobile={mobile} onTap={onTap} />}
			{open &&
				item.extended &&
				item.extended.length > 0 &&
				item.extended.map((item, index) => {
					return item.title !== `Obras` ? <ExtendedItem key={index} mobile={mobile} {...item} /> : null;
				})}
		</Stack>
	);
};

const BioTimelineItemGallery = ({ images, mobile, onTap }) => {
	return (
		<Stack
			direction={`horizontal`}
			alignment={`start`}
			background={``}
			width={`auot`}
			height={`auto`}
			gap={mobile ? 5 : 10}
			style={{ marginLeft: mobile ? `0vw` : `1vw`, marginTop: `1vw` }}>
			{images &&
				images.map((image) => (
					<Frame
						key={image}
						size={mobile ? `15vw` : `5vw`}
						whileHover={{ scale: 1.2, zIndex: 1 }}
						initial={{ zIndex: 0 }}
						border={`1px solid grey`}
						background={``}>
						<LazyImage key={image} src={image} onTap={onTap} mobile={mobile} />
					</Frame>
				))}
		</Stack>
	);
};

const LazyImage = ({ src, mobile, onTap }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const side = mobile ? `15vw` : `5vw`;
	return (
		<React.Fragment>
			<Frame
				size={side}
				background={`lightgrey`}
				animate={isLoaded ? `loaded` : `loading`}
				initial={{
					opacity: 0,
				}}
				variants={{
					loaded: {
						opacity: 1,
					},
					loading: {
						opacity: [0.1, 1],
						transition: {
							yoyo: Infinity,
							delay: Math.random() * 0.1,
						},
					},
				}}
				center
			/>
			<Frame
				animate={isLoaded ? `visible` : `hidden`}
				initial={`hidden`}
				size={side}
				onTap={() => (isLoaded ? onTap(src) : null)}
				background={``}
				variants={{
					visible: {
						opacity: 1,
					},
					hidden: {
						opacity: 0,
					},
				}}
				center
				overflow={`hidden`}>
				<img
					src={src}
					onLoad={() => {
						setIsLoaded(true);
					}}
					style={{ width: side, height: side, objectFit: `cover` }}
				/>
			</Frame>
		</React.Fragment>
	);
};
const ExtendedItem = React.memo(({ title, content, mobile }) => {
	return (
		<Stack x={`1vw`} alignment={`start`} width={`100%`} height={`auto`}>
			<h4
				style={{
					fontFamily: `HelveticaNeueMedium`,
					fontSize: mobile ? `4vw` : `1vw`,
					marginTop: `1vh`,
				}}>
				{title}
			</h4>
			<p
				style={{
					fontFamily: `HelveticaNeue`,
					fontSize: mobile ? `4vw` : `1vw`,
					lineHeight: 1.5,
				}}
				dangerouslySetInnerHTML={{ __html: dompurify.sanitize(content) }}></p>
		</Stack>
	);
});

const ExtendedItemButton = ({ open, toggle, mobile }) => (
	<Frame size={mobile ? `7vw` : `2vw`} background={``} onTap={toggle}>
		<img src={open ? assets.ChevronOpen : assets.ChevronClose} />
	</Frame>
);

const Exhibitions = React.memo(({ exhibitions, exhibitionsTitles, mobile }) => {
	const [single, setSingle] = useState([]);
	const [collective, setCollective] = useState([]);
	const [posthumous, setPosthumous] = useState([]);

	useEffect(() => {
		if (!isEmpty(exhibitions)) {
			setSingle(exhibitions.single);
			setCollective(exhibitions.collective);
			setPosthumous(exhibitions.posthumous);
		}
	}, [exhibitions]);
	return (
		<>
			<ExhibitionTitle mobile={mobile}>{exhibitionsTitles && exhibitionsTitles[`single`]}</ExhibitionTitle>
			{single &&
				single.map((e, index) => (
					<ExhibitionItem key={index} mobile={mobile}>
						<b>{e.date}</b>
						<br />
						<span className='local'>{e.local}</span>
						<br />
						<span className='info'>{e.info}</span>
					</ExhibitionItem>
				))}
			<ExhibitionTitle mobile={mobile}>{exhibitionsTitles && exhibitionsTitles[`collective`]}</ExhibitionTitle>
			{collective &&
				collective.map((e, index) => (
					<ExhibitionItem key={index} mobile={mobile}>
						<b>{e.date}</b>
						<br />
						<span className='local'>{e.local}</span>
						<br />
						<span className='info'>{e.info}</span>
					</ExhibitionItem>
				))}
			<ExhibitionTitle mobile={mobile}>{exhibitionsTitles && exhibitionsTitles[`posthumous`]}</ExhibitionTitle>
			{posthumous &&
				posthumous.map((e, index) => (
					<ExhibitionItem key={index} mobile={mobile}>
						<b>{e.date}</b>
						<br />
						<span className='local'>{e.local}</span>
						<br />
						<span className='info'>{e.info}</span>
					</ExhibitionItem>
				))}
		</>
	);
});

const ExhibitionTitle = styled.h3`
	font-family: HelveticaNeueMedium;
	font-size: ${(props) => (props.mobile ? `6vw` : `1.5vw`)};
	margin: ${(props) => (props.mobile ? `10vw 0 1vw 0` : `3vw 0 1vw 0`)};
`;

const ExhibitionItem = styled.div`
	width: 80%;
	height: auto;
	font-size: ${(props) => (props.mobile ? `4.5vw` : `1vw`)};
	margin-top: ${(props) => (props.mobile ? `5vw` : `1vw`)};

	b {
		font-family: HelveticaNeueMedium;
	}
	span.local {
		font-family: HelveticaNeueThin;
	}
	span.info {
		font-family: HelveticaNeue;
	}
`;

const Content = styled.div`
	font-size: 1vw;
	line-height: 1.5;
	width: 40vw;
	height: auto;
	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: black;
		font-family: HelveticaNeueBlack;
	}
	h2 {
		font-size: 1vw;
		font-family: HelveticaNeueBlack;
		margin-top: 7vh;
	}
`;

const Title = styled.h1`
	font-size: 3.5vw;
	font-family: HelveticaNeueBold;
	font-weight: normal;
	line-height: 0.9;
	width: 100%;
	height: 15vh;
`;

const TitleMobile = styled.h1`
	padding: 0;
	font-size: 8vw;
	font-family: HelveticaNeueBold;
	font-weight: normal;
	line-height: 0.9;
	width: 80vw;
	maginleft: 10vw;
	// background-color: red;
`;

const ContentMobile = styled.div`
	font-size: 1vw;
	line-height: 1.5;
	width: 40vw;
	height: auto;
	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: black;
		font-family: HelveticaNeueBlack;
	}
	h2 {
		font-size: 5vw;
		font-family: HelveticaNeueBlack;
		margin-top: 7vh;
	}
	h2:first-child {
		margin-top: 0;
	}
`;
